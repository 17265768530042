@font-face {
  font-family: PP Neue Montreal;
  src: url("PPNeueMontreal-Regular.5e0546b4.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: PP Neue Montreal;
  src: url("Neue-Montreal-Medium.0edc0724.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: PP Neue Montreal;
  src: url("Neue-Montreal-Bold.836b8798.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: Signal Mono;
  src: url("Signal Mono-Regular-Web.b0c13c96.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
}

.not-found-background {
  background-image: linear-gradient(#000000ab 0%, #0000 200%), url("not-found-background-mobile.6650a171.webp");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

.not-found-container {
  text-align: center;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
  height: calc(100vh - 12px);
  margin: 0 auto;
  padding-top: 12%;
  padding-bottom: 10rem;
  display: flex;
}

.not-found-container-subtitle {
  text-transform: uppercase;
  max-width: 640px;
}

.main-action-button {
  width: 100%;
  position: absolute;
  bottom: 12px;
  right: 0;
}

.main-action-button a.btn {
  border: 0;
  width: 100%;
  max-width: 100%;
  margin: 0;
}

footer {
  background-color: #00ff89;
  height: 12px;
}

@media only screen and (width >= 992px) {
  .not-found-container {
    height: 100vh;
  }

  .not-found-background {
    background-image: linear-gradient(#000000ab 0%, #0000 100%), url("not-found-background.2de1592c.webp");
  }

  .main-action-button {
    width: initial;
    position: relative;
  }

  .main-action-button a.btn {
    border: initial;
    width: initial;
    margin: initial;
    max-width: initial;
  }

  footer {
    display: none;
  }
}

/*# sourceMappingURL=not-found.70abf0c3.css.map */
