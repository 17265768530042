@use 'base';

$footer-height: 12px;

.not-found {
  &-background {
    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.67) 0%,
        rgba(0, 0, 0, 0) 200%
      ),
      url('../assets/img/not-found-background-mobile.webp');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
  }

  &-container {
    margin: 0 auto;
    padding-top: 12%;
    padding-bottom: 10rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    height: calc(100vh - $footer-height);

    &-subtitle {
      max-width: 640px;
      text-transform: uppercase;
    }
  }
}

.main-action-button {
  a.btn {
    border: 0;
    width: 100%;
    margin: 0;
    max-width: 100%;
  }
  position: absolute;
  right: 0;
  bottom: $footer-height;
  width: 100%;
}

footer {
  height: $footer-height;
  background-color: base.$color-success;
}

@media only screen and (min-width: base.$large-devices) {
  .not-found {
    &-container {
      height: 100vh;
    }

    &-background {
      background-image: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.67) 0%,
          rgba(0, 0, 0, 0) 100%
        ),
        url('../assets/img/not-found-background.webp');
    }
  }

  .main-action-button {
    a.btn {
      border: initial;
      width: initial;
      margin: initial;
      max-width: initial;
    }
    position: relative;
    width: initial;
  }

  footer {
    display: none;
  }
}
